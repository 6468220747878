import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    Un complement perfecte amb l'anterior llibre que et permetrà profunditzar en el
                    teu procés d'estudi; una manera diferent d'aprendre.
                </p>

                <p>
                    Totes les preguntes contenen la resposta correcta i en moltes d'elles hem
                    ampliat informació.
                </p>

                <p>Trobaràs tots els temes de les bases.</p>

                <p>
                    A més, amb la compra del llibre t'habilitem de manera{" "}
                    <strong>TOTALMENT GRATUÏTA</strong> el mòdul de les 500 preguntes VOL 2 en
                    format test per practicar el que has estudiat i posar-te a prova!
                </p>

                <p>
                    Un mòdul que podràs fer servir a través de l'aplicació iGuB a qualsevol moment i
                    allà on vulguis.
                </p>

                <p>
                    Vols tenir una bona base de temari? Fes-te amb aquest llibre únic i no et quedis
                    enrere!
                </p>

                <p>Al llibre trobaràs 17 temes que a continuació us detallem:</p>

                <ul>
                    <li>
                        La Constitució espanyola de 1978. Estructura i principis generals. Drets i
                        deures fonamentals. L'estructura territorial de l'Estat: "Principis generals
                        i l'Administració local"
                    </li>
                    <li>
                        Llei Orgànica 6/2006 de 19 de juliol, de Reforma de l'Estatut d'Autonomia de
                        Catalunya
                    </li>
                    <li>
                        Llei 16/1991, de 10 de juliol, de les policies locals. Estructura,
                        organització i funcions de les policies locals
                    </li>
                    <li>Llei Orgànica 2/1986 de 13 de març, de forces i cossos de seguretat.</li>
                    <li>Llei 7/1985, de 2 d'abril, reguladora de les Bases del Règim Local.</li>
                    <li>
                        Llei 39/2015, d'1 d'octubre, de Procediment Administratiu Comú de les
                        Administracions Públiques.
                    </li>
                    <li>Llei 7/2007, de 12 d'abril, de l'Estatut bàsic de l'empleat públic.</li>
                    <li>
                        Llei Orgànica 4/2015 de 30 de març, de protecció a la seguretat ciutadana
                    </li>
                    <li>
                        Llei 4/2003, de 7 d'abril, d'ordenació del sistema de seguretat pública de
                        Catalunya: disposicions generals i relacions amb els ciutadans
                    </li>
                    <li>
                        Llei Orgànica 6/1984, de 24 de maig, reguladora del procediment d'Habeas
                        Corpus
                    </li>
                    <li>Llei Orgànica 10/1995 de 23 de novembre, del Codi Penal.</li>
                    <li>
                        Codi d'Ètica de la Policia de Catalunya. Principis, valors i competències
                    </li>
                    <li>
                        Reial decret 137/1993, de 29 de gener, que aprova el Reglament d'Armes.
                        Decret 219/1996, de 12 de juny, pel qual s'aprova el Reglament d'armament de
                        les policies locals
                    </li>
                    <li>
                        Llei Orgànica 5/2000, de 12 de gener, reguladora de la responsabilitat penal
                        dels menors. La detenció de menors.
                    </li>
                    <li>
                        Llei 27/2003, de 31 de juliol, reguladora de l'ordre de protecció de les
                        víctimes de la violència domèstica
                    </li>
                    <li>
                        Ètica i deontologia professional. Declaració Universal dels Drets Humans de
                        l'ONU. Carta dels drets fonamentals de la UE
                    </li>
                    <li>
                        Institucions de la Unió Europea: el Parlament, el Consell Europeu, el
                        Consell de la Unió Europea, la Comissió, el Tribunal de Justícia de la Unió
                        Europea. L'ordenament jurídic comunitari: dret originari i dret derivat
                    </li>
                </ul>

                <p>
                    Estem segurs que la combinació dels 2 llibres et donaran un impuls a l'hora de
                    posicionar-te entre els millors.
                </p>

                <p>
                    <strong>"L’èxit va lligat a la persistència"</strong>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Descobreix l'arma definitiva per preparar-te amb èxit als exàmens oficials! Amb aquest nou llibre de 500 preguntes i el mòdul de test inclòs, reforça el teu coneixement i assoleix els teus objectius amb confiança. No deixis que res t’aturi!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">

                <Title>
                    Les 500 preguntes de temari més repetides als exàmens de policia local (2a part)
                </Title>
                <Text>
                    Tras l'èxit del vol. 1...
                    <br />
                    <br />
                    Us presentem un nou llibre amb 500 preguntes noves i totalment diferents del VOL
                    1.
                    <br />
                    <br />
                    El llibre conté un recopilatori de les preguntes oficials que han aparegut en
                    els exàmens de les convocatòries més actuals.
                    <br />
                    <br />
                    Un complement perfecte amb l'anterior llibre que et permetrà profunditzar en el
                    teu procés d'estudi; una manera diferent d'aprendre.
                    <br />
                    <br />
                    Totes les preguntes contenen la resposta correcta i en moltes d'elles hem
                    ampliat informació.
                    <br />
                    <br />
                    Trobaràs tots els temes de les bases.
                    <br />
                    <br />A més amb la compra del llibre t'habilitem de manera
                    <strong>TOTALMENT GRATUÏTA</strong> el mòdul de les 500 preguntes VOL 2 en
                    format test per practicar el que has estudiat i posar-te a prova!
                    <br />
                    <br />
                    Un mòdul que podràs fer servir a través de l'aplicació iGuB a qualsevol moment i
                    allà on vulguis.
                    <br />
                    <br />
                    Vols tenir una bona base de temari? Fes-te amb aquest llibre únic i no et quedis
                    enrere!
                    <br />
                    <br />
                    Al llibre trobaràs 17 temes que a continuació us detallem:
                    <br />
                    <br />
                    - La Constitució espanyola de 1978. Estructura i principis generals. Drets i
                    deures fonamentals. L'estructura territorial de l'Estat: "Principis generals i
                    l'Administració local"
                    <br />
                    <br />
                    - Llei Orgànica 6/2006 de 19 de juliol, de Reforma de l'Estatut d'Autonomia de
                    Catalunya
                    <br />
                    <br />
                    - Llei 16/1991, de 10 de juliol, de les policies locals. Estructura,
                    organització i funcions de les policies locals
                    <br />
                    <br />
                    - Llei Orgànica 2/1986 de 13 de març, de forces i cossos de seguretat.
                    <br />
                    <br />
                    - Llei 7/1985, de 2 d'abril, reguladora de les Bases del Règim Local.
                    <br />
                    <br />
                    - Llei 39/2015, d'1 d'octubre, de Procediment Administratiu Comú de les
                    Administracions Públiques.
                    <br />
                    <br />
                    - Llei 7/2007, de 12, d'abril, de l'Estatut bàsic de l'empleat públic.
                    <br />
                    <br />
                    - Llei Orgànica 4/2015 de 30 de març, de protecció a la seguretat ciutadana
                    <br />
                    <br />
                    - Llei 4/2003, de 7 d'abril, d'ordenació del sistema de seguretat pública de
                    Catalunya: disposicions generals i relacions amb els ciutadans. - Llei Orgànica
                    6/1984, de 24 de maig, reguladora del procediment d'Habeas Corpus
                    <br />
                    <br />
                    - Llei Orgànica 10/1995 de 23 de novembre, del Codi Penal.
                    <br />
                    <br />
                    -Codi d'Ètica de la Policia de Catalunya. Principis, valors i competències
                    <br />
                    <br />
                    - Reial decret 137/1993, de 29 de gener, que aprova el Reglament d'Armes. Decret
                    219/1996, de 12 de juny, pel qual s'aprova el Reglament d'armament de les
                    policies locals
                    <br />
                    <br />
                    - Llei Orgànica 5/2000, de 12 de gener, reguladora de la responsabilitat penal
                    dels menors. La detenció de menors.
                    <br />
                    <br />
                    -Llei 27/2003, de 31 de juliol, reguladora de l'ordre de protecció de les
                    víctimes de la violència domèstica
                    <br />
                    <br />
                    - Ètica i deontologia professional. Declaració Universal dels Drets Humans de
                    l'ONU. Carta dels drets fonamentals de la UE
                    <br />
                    <br />
                    - Institucions de la Unió Europea: el Parlament, el Consell Europeu, el Consell
                    de la Unió Europea, la Comissió, el Tribunal de Justícia de la Unió Europea.
                    L'ordenament jurídic comunitari: dret originari i dret derivat
                    <br />
                    <br />
                    Estem segurs que la combinació dels 2 llibres et donaran un impuls a l'hora de
                    posicionar-te entre els millors.
                    <br />
                    <br />
                    <strong>"L'èxit va lligat a la persistència"</strong>
                </Text>
            </div>
        </ProductTemplate>
    );
};

export default Product;
*/
